/* homepage.css */
.cards-container {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
  justify-content: space-between;
  /* Adjust this property to control the space between cards */
}

.cards-container .card {
  flex: 1;
  margin: 10px;
  /* Add any other card-related styling here */
}
