.frame {
  align-items: flex-start;
  background-color: #333;
  border-radius: 5px;
  display: inline-flex;
  gap: 10px;
  padding: 15px 48px;
  position: relative;
  cursor: pointer;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}
