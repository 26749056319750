// Footer.scss
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px; // Set an appropriate height for the footer
    background-color: #333; // Adjust the background color as needed

    .footer__text {
        color: #fff; // Adjust the text color as needed
        font-size: 14px;
        text-align: center; // Align the text in the center
    }
}