// LinkContainer.scss
.linkContainer {
  display: flex;
  background-color: #ccc430;
  padding: 10px 20px; // Add padding to create spacing between links

  @media screen and (max-width: 1076px) {
    padding: 0px 4px; // Add padding to create spacing between links
  }
  .link {
    text-decoration: none;
    color: #fff;
    padding: 10px;
    margin: 0 10px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid #fff;
    }

    &.active {
      border-bottom: 2px solid #fff;
    }
  }
}
