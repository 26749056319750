// Header.scss
.mainHeader {
  display: flex;
  align-items: center;

  width: 100%;
  height: 15vh; // Set an appropriate height for the header
  background-color: #333;
  padding: 0 20px; // Add some padding to create space around the logo and links
  box-sizing: border-box; // Include padding in the width calculation

  .mainHeader__logo-link {
    width: 8vh;
  }

  .mainHeader__logo {
    width: 100%;
    height: auto;
  }

  #pcHeader {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    .mainHeader__title {
      flex: 1; // Let the title take the remaining available space
      margin: 0 20px; // Add margin between the logo and the title
      color: #fff;
      font-size: 2rem;
      @media screen and (max-width: 1076px) {
        font-size: 24px;
        margin: 0 12px;
      }
      @media screen and (max-width: 900px) {
        font-size: 16px;
        margin: 0 12px;
      }
    }
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
  #mobileHeader {
    margin-left: auto;
    margin-right: 0;
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
}

//mobile menu header

#mobileMenu {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 99;
  background-color: #333;
  width: 100%;
  height: 100%;
  #CloseIcon {
    position: absolute;
    top: 32px;
    right: 24px;
  }
  #Menu {
    margin-top: 120px;
    .link {
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      color: white;
      text-decoration: none;
      letter-spacing: 2px;
    }
    .underlined {
      text-decoration: underline;
    }
  }
}
