#quotesPageTitle {
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 500;
  color: #ccc430;
  text-align: center;
  margin: 0;
  margin-top: 48px;
}
#quotesPageSubTitle {
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin-top: 8px;
}
.quotesSection {
  width: 50%;
  max-width: 705px;
  height: 100%;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px #0000001a;
  background-color: #fff;
  border-radius: 8px;

  display: flex;
}
#sendAni {
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0%;
  bottom: 0%;
  transform: rotateX(60deg) rotateY(180deg) rotateZ(-10deg) translateY(55%)
    translateX(-24%);
}
.quotesForm {
  position: relative;
  width: 100%;
  margin: 10px;
  margin-left: 10px;
  padding-left: 1%;
  padding-right: 2%;
  padding-top: 50px;
  margin-top: 15px;
}

#column {
  width: 100%;
  display: flex;
  padding: 0;
  margin-bottom: 24px;
}
#spaceBetField {
  width: 8%;
}
#sendButtonContainer {
  width: 100%;
  text-align: right;
  margin-bottom: 32px;
}

@media (max-width: 1024px) {
  .quotesSection {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .quotesSection {
    width: 95%;
  }
}
@media (max-width: 500px) {
  #quotesPageTitle {
    font-size: 32px;
  }
  #quotesPageSubTitle {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin-top: 8px;
  }
}
