#textFieldContainer {
  width: 100%;
  margin: 0;
}
#checkBoxFieldLabel {
  display: inline-block;
  color: #8d8d8d;
  font-size: 12px;
  font-family: "Poppins-Medium", Helvetica;
  font-weight: "500";
  line-height: 20px;
  margin-bottom: 12px;
}
#textFieldInput {
  width: 100%;
  border: none;
  line-height: 32px;
  font-size: 16px;
  border-bottom: 1px solid #8d8d8d;
  outline: none;
}
.checkBoxOptionLabel {
  display: inline-block;
  margin-right: 24px;
  margin-bottom: 12px;
  text-wrap: nowrap;
}
