// ServicesPage.scss
.services-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
  }

  .service {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .service-content {
      text-align: left;

      h2 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    img {
      width: 300px;
      height: 460px;
      border-radius: 5px;
      object-fit: cover;
      @media screen and (min-width: 768px) {
        margin-left: 20px;
      }
    }
  }
}
