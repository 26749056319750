// AboutPage.scss
.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.about-section,
.contracted-section {
  text-align: left;
  /* Left align the text */
  margin-bottom: 40px;

  h1,
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .image-container {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;

    img {
      width: 100%;
      border-radius: 5px;
    }

    @media screen and (min-width: 768px) {
      margin-left: 20px;
      margin-top: 0;
    }
  }
}
