#contactPageTitle {
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 500;
  color: #ccc430;
  text-align: center;
  margin: 0;
  margin-top: 48px;
}
#contactPageSubTitle {
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin-top: 8px;
}
.contactSection {
  width: calc(100% - 40px);
  max-width: 1196px;
  height: 100%;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px #0000001a;
  background-color: #fff;
  border-radius: 8px;

  display: flex;
}
.contactDetails {
  width: 40%;
  /* background-color: #011c2b; */
  background-color: #333;
  margin: 10px;
  margin-right: 0px;
  border-radius: 4px;
  color: #fff;
  position: relative;
}
#contactDetailsTitle {
  color: "white";
  font-size: 28px;
  font-family: "Poppins", Helvetica;
  font-weight: "600";
  margin-top: 8%;
  margin-left: 8%;
}
#contactDetailsSubTitle {
  color: "#C9C9C9";
  font-size: 18px;
  font-family: "Poppins", Helvetica;
  font-weight: "400";
  margin-left: 8%;
}
.contactDetailBox {
  width: 80%;
  display: flex;
  margin-left: 8%;
  align-items: center;
}
#sendAni {
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0%;
  bottom: 0%;
  transform: rotateX(60deg) rotateY(180deg) rotateZ(-10deg) translateY(55%)
    translateX(-24%);
}
.contactDetailBox img {
  width: 24px;
  height: 24px;
  margin-right: 24px;
}
.contactForm {
  position: relative;
  width: 60%;
  margin: 10px;
  margin-left: 10px;
  padding-left: 1%;
  padding-right: 2%;
  padding-top: 50px;
  margin-top: 15px;
}

#column {
  width: 100%;
  display: flex;
  padding: 0;
  margin-bottom: 24px;
}
#spaceBetField {
  width: 8%;
}
#sendButtonContainer {
  width: 100%;
  text-align: right;
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  .contactSection {
    flex-direction: column;
  }
  .contactDetails {
    width: calc(100% - 20px);
  }
  .contactForm {
    width: calc(100% - 30px);
  }
  #contactDetailsTitle {
    font-size: 16px;
  }
  #contactDetailsSubTitle {
    font-size: 12px;
  }
  #sendAni {
    display: none;
  }
}
@media (max-width: 600px) {
  .contactSection {
    width: 95%;
  }
}
@media (max-width: 500px) {
  #contactPageTitle {
    font-size: 32px;
  }
  #contactPageSubTitle {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin-top: 8px;
  }
}
