#textAreaFieldContainer {
  width: 100%;
  margin: 0;
}
#textAreaFieldLabel {
  color: #8d8d8d;
  font-size: 12px;
  font-family: "Poppins-Medium", Helvetica;
  font-weight: "500";
  line-height: 20px;
  word-wrap: "break-word";
}
#textAreaFieldInput {
  width: 100%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #8d8d8d;
  outline: none;
  resize: vertical;
}
