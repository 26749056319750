// Hero.scss
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; // Adjust the color as per your preference
}

.hero-content {
  width: 80%; // Adjust this value to set the width of the hero content
  display: flex;
  align-items: center;
  margin: 2%;

  @media screen and (max-width: 768px) {
    width: 98%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
    flex-direction: column-reverse;
  }
  .hero-text {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column; // To stack the text vertically
    align-items: flex-start; // Align text to the left

    h2 {
      margin-bottom: 10px; // Add some space between heading and paragraph
    }

    p {
      color: black; // Text color on the overlay
      font-size: 18px;
      text-align: left; // Align text to the left
    }
  }

  .hero-image {
    width: 40%; // Adjust this value to set the width of the image
    height: auto;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
}
