// GetQuoteButton.scss
.getQuoteButton {
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid black; // Black outline
    background-color: #ccc430; // Background color
    color: #fff; // Text color
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: #b2a71d; // Darker color on hover
    }
}

.button-container {
    margin-left: 20px; // Add margin to separate the button from the link container
}