.loaderBackground {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 90;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.loader {
  text-align: center;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #ae1536; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
