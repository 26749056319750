.card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    flex: 1;
        margin: 10px;
}

.card:hover {
    background-color: #f0f0f0;
    /* Change this to the desired hover color */
}

.card-link {
    display: block;
    text-decoration: none;
    color: inherit;
}

.image-container {
    position: relative;
    width: 100%;
    height: 200px;
    /* Set the desired height of the card image */
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    transition: background-color 0.3s ease;
}

.card-content h3 {
    margin: 0;
}

/* Add any additional styling for the title text here */