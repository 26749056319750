.popup {
  position: fixed;
  display: flex;
  z-index: 25;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #00000073;
}
.popup > div > button:first-child {
  position: absolute;
  right: 0px;
  top: -41px;
  overflow: visible;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
}
.popup > div:not(.loader) {
  position: relative;
  max-width: 362px;
  width: calc(100% - 20px);
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
}
.popup div h3 {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 24px 0px 16px 22px;
}
.popup div p {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 22px 24px 25px;
}
.responseBtn button {
  width: 142px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  border: 1px solid #333;
  box-sizing: border-box;
  border-radius: 6px;
}
.responseBtn {
  margin-bottom: 24px;
}
.loaderBackground {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  text-align: center;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #ae1536; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 475px) {
  .responseBtn button {
    width: 130px;
  }
  .popup .responseBtn button:nth-child(2) {
    margin-left: 8px;
  }
}
